/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ./images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('./images', true)
// const imagePath = (name) => images(name, true)

//= require popper
//= require bootstrap

window.addEventListener('DOMContentLoaded', event => {

  // Navbar shrink function
  var navbarShrink = function () {
      const navbarCollapsible = document.body.querySelector('#mainNav');
      if (!navbarCollapsible) {
          return;
      }
      if (window.scrollY === 0) {
          navbarCollapsible.classList.remove('navbar-shrink')
      } else {
          navbarCollapsible.classList.add('navbar-shrink')
      }

  };

  // Shrink the navbar
  navbarShrink();

  // Shrink the navbar when page is scrolled
  document.addEventListener('scroll', navbarShrink);

  // Activate Bootstrap scrollspy on the main nav element
  const mainNav = document.body.querySelector('#mainNav');
  if (false) {
      new bootstrap.ScrollSpy(document.body, {
          target: '#mainNav',
          offset: 74,
      });
  };

  // Collapse responsive navbar when toggler is visible
  const navbarToggler = document.body.querySelector('.navbar-toggler');
  const responsiveNavItems = [].slice.call(
      document.querySelectorAll('#navbarResponsive .nav-link')
  );
  responsiveNavItems.map(function (responsiveNavItem) {
      responsiveNavItem.addEventListener('click', () => {
          if (window.getComputedStyle(navbarToggler).display !== 'none') {
              navbarToggler.click();
          }
      });
  });

  document.addEventListener("DOMContentLoaded", function() {
    var galeriaModal = document.getElementById('galeriaModal');
    var modal = new bootstrap.Modal(galeriaModal);
  });

  // Helper Modal
  document.addEventListener("DOMContentLoaded", function() {
    var CarouselTroyak = document.getElementById('CarouselTroyak');
    var carousel = new bootstrap.Carousel(CarouselTroyak);
  });

  document.addEventListener("DOMContentLoaded", function() {
    var CarouselBraford = document.getElementById('CarouselBraford');
    var carousel = new bootstrap.Carousel(CarouselBraford);
  });

  document.addEventListener("DOMContentLoaded", function() {
    var CarouselLosChilcos = document.getElementById('CarouselLosChilcos');
    var carousel = new bootstrap.Carousel(CarouselLosChilcos);
  });

  document.addEventListener("DOMContentLoaded", function() {
    var CarouselClavel = document.getElementById('CarouselClavel');
    var carousel = new bootstrap.Carousel(CarouselClavel);
  });
});

//Con esto marco el tab actual de la pag
let currentPath = window.location.pathname;
/// Elementos de li
const tabs = ["about", "contact", "projects"];

tabs.forEach(e => {
  if ("/" + e == currentPath) {
    /// Agregar tab- para hacer que coincida la Id
    setActive("tab-" + e);
  } else if (currentPath == "/") {
    setActive("tab-home");
  }
});

/// Funcion que asigna la clase active
function setActive(id) {
  document.getElementById(id).classList.add("show-active")
}